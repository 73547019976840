<template>
  <product
    title="Amazfit PowerBuds Pro"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="999"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://item.jd.com/100026981506.html"
  ></product>
</template>

<script>
// import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 'powerbuds-pro',
  components: {
    Product
  },
  data () {
    return {
      slogan:
        '运动心率 | 颈椎保护 | 听力健康 | 40dB 全场景主动降噪 | 三麦通话降噪',
      colors: ['清爽白'],
      video: {
        url:
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/powerbuds-pro-cn-2.mp4',
        poster:
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/1.png'
      },
      swiperData: [
        [
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m/detail-1.png',
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m/detail-2.png',
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m/detail-3.png',
          'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m/detail-4.png'
        ]
      ],
      relativedProducts: [],
      detailImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/1.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/2.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/3.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/4.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/5.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/6.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/7.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/8.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/9.jpg'
      ],
      paramImages: [
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/10.jpg',
        'https://site-cdn.huami.com/files/amazfit/cn/powerbuds-pro/m2/11.jpg'
      ]
    }
  },
  methods: {}
}
</script>

<style></style>
